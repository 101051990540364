/* eslint-disable no-param-reassign */
/* eslint-env browser */
import ready from '../components/ready';
import {
  setResponsiveNav,
  setResponsiveToggle,
} from '../components/nav';
import {
  isElementWithClassNameInEventPath, setMetaThemeColor,
  horizontalScrollButtonNext, horizontalScrollButtonPrevious,
} from '../components/helpers';
import { setResponsiveTable } from '../components/table';
import { setTabs } from '../components/tabs';
import { setContentToggle } from '../components/content-toggle';
import {
  closeAllContentTogglesInToolbar,
  contentToggleEdgeHandling,
  findToolbar,
  lockBodyScroll,
  unlockBodyScroll,
} from '../components/toolbar';

let windowInnerWidth = 0;

ready(() => {
  const tabbedContent = document.querySelectorAll('.tabbed-content');
  const tabbedObjects: ITabbedObject[] = [];
  tabbedContent.forEach((tab) => tabbedObjects.push({
    domElement: tab,
    rendered: false,
  }));
  setTabs(tabbedObjects);
  setMetaThemeColor();

  const navElements = document.querySelectorAll('.nav:not(.nav--vertical)');
  navElements.forEach((n) => setResponsiveNav(navElements, n));
  setResponsiveToggle(navElements);

  const tables = document.querySelectorAll('.table--wrap-medium-width, .table--wrap-default-width');
  tables.forEach((table) => setResponsiveTable(table));

  // Almindelige content toggles
  const toggles = document.querySelectorAll('.box .content-toggle__content, .profile-header .content-toggle__content');
  toggles.forEach((toggle) => {
    setContentToggle(toggle);
  });

  // Hortizontal scroll next button
  const scrollButtonsNext = document.querySelectorAll('.button.button--small.button--icon.button--icon--hide-label.icon-next');
  const scrollButtonsPrevious = document.querySelectorAll('.button.button--small.button--icon.button--icon--hide-label.icon-previous');
  horizontalScrollButtonNext(scrollButtonsNext);
  horizontalScrollButtonPrevious(scrollButtonsPrevious);

  // Kursuskatalog
  const togglesInToolbars = document.querySelectorAll('.toolbar .content-toggle__content');
  togglesInToolbars.forEach((toggle) => {
    setContentToggle(
      toggle,
      (popout: HTMLElement) => {
        contentToggleEdgeHandling(popout);
        lockBodyScroll();
      },
      (content: HTMLElement) => closeAllContentTogglesInToolbar(findToolbar(content)),
      (content: HTMLElement, button: HTMLButtonElement) => {
        const ariaExpanded = button.getAttribute('aria-expanded');
        const toolbar = findToolbar(content);
        if (ariaExpanded === 'true') {
          toolbar?.classList.add('toolbar--has-popout');
        } else {
          toolbar?.classList.remove('toolbar--has-popout');
        }

        content.addEventListener('click', (event: MouseEvent) => {
          if ((event.target as HTMLElement).classList.contains('toolbar__popout')
            && !isElementWithClassNameInEventPath(event as MouseEvent, 'toolbar__filter__content')) {
            closeAllContentTogglesInToolbar(toolbar);
            unlockBodyScroll();
          }
        });
      },
    );
  });

  window.addEventListener('load', () => {
    windowInnerWidth = window.innerWidth;
  });

  window.addEventListener('resize', () => {
    if (windowInnerWidth > 0 && window.innerWidth !== windowInnerWidth) {
      windowInnerWidth = window.innerWidth;
      navElements.forEach((n) => setResponsiveNav(navElements, n));
      setTabs(tabbedObjects);
    }
  });
});
