/* eslint-disable wrap-iife */
/* eslint-disable import/prefer-default-export */
/* eslint-env browser */
const copyToClipboard = (str: string): void => {
  // eslint-disable-next-line no-console
  navigator.clipboard.writeText(str).then(() => console.log(`${str} written to clipboard`));
};

const isElementWithClassNameInEventPath = (event: MouseEvent, className: string): boolean => {
  let found = false;
  const path = event.composedPath();
  path.forEach((p: EventTarget): void => {
    if ((p as HTMLElement).classList) {
      if ((p as HTMLElement).classList.contains(className)) {
        found = true;
      }
    }
  });
  return found;
};

const setMetaThemeColor = (): void => {
  const body = document.querySelector('body');
  if (body) {
    const bgColor = window.getComputedStyle(body).getPropertyValue('--color-background');
    const existingMeta = document.querySelector('meta[name="theme-color"]') as HTMLMetaElement;
    if (existingMeta) {
      existingMeta.content = bgColor;
    } else {
      const meta = document.createElement('meta');
      meta.name = 'theme-color';
      meta.content = bgColor;
      document.getElementsByTagName('head')[0].appendChild(meta);
    }
  }
};

const getBaseFontSize = (): number => parseInt(window.getComputedStyle(document.documentElement).fontSize.replace('px', ''), 10);

// eslint-disable-next-line max-len
const convertRemToPixels = (rem: number): number => rem * parseFloat(getComputedStyle(document.documentElement).fontSize);

const isViewportBelow = (mediaQuery: string): boolean => {
  const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const mediaQueryValue = convertRemToPixels(parseInt(getComputedStyle(document.documentElement).getPropertyValue(mediaQuery).replace('rem', ''), 10));
  return viewportWidth < mediaQueryValue;
};

const setScrollbarWidthVariable = (): void => {
  const scrollbarWidth = window.innerWidth - document.body.clientWidth;
  document.documentElement.style.setProperty('--vertical-scrollbar-width', `${scrollbarWidth}px`);
};

const overflowsY = (element: HTMLElement): boolean => element.offsetHeight !== element.scrollHeight;
const overflowsX = (element: HTMLElement): boolean => element.offsetWidth !== element.scrollWidth;
// eslint-disable-next-line max-len
const elementOverflows = (element: HTMLElement): boolean => overflowsY(element) || overflowsX(element);

const setHorizontalScroll = (element: HTMLElement): void => {
  const content = element.querySelector('.horizontal-scroll__scrollable-content');
  if (!overflowsX(content as HTMLElement)) {
    element.classList.add('horizontal-scroll--no-overflow');
    content?.removeAttribute('tabindex');
  } else {
    content?.setAttribute('tabindex', '0');
  }
};

const focusableSelector = 'a[href]:not([tabindex="-1"]), area[href]:not([tabindex="-1"]), input:not([disabled]):not([tabindex="-1"]), select:not([disabled]):not([tabindex="-1"]), textarea:not([disabled]):not([tabindex="-1"]), button:not([disabled]):not([tabindex="-1"]), iframe:not([tabindex="-1"]), [tabindex]:not([tabindex="-1"]), [contentEditable=true]:not([tabindex="-1"])';

// Scroller 80% på horisontal scroll til højre og sætter knappen disabled når man rammer bunden
const horizontalScrollButtonNext = (nextButtons: NodeListOf<Element>): void => {
  if (nextButtons) {
    nextButtons.forEach((nextButton) => {
      const topElem = nextButton?.parentElement?.parentElement?.parentElement?.parentElement;
      if (topElem) {
        const scrollElement = topElem.querySelector('.horizontal-scroll__scrollable-content');
        if (scrollElement) {
          nextButton.addEventListener('click', () => {
            // Scroller 80% af den synlige bredde på horizontal scroll til højre
            const scrollAmount = 0.8 * scrollElement.clientWidth;
            scrollElement.scrollLeft += scrollAmount;
          });
          // Sætter Aria-label og visually-disabled klassen på knappen hvis scroll har ramt bunden
          scrollElement.addEventListener('scroll', () => {
            if ((scrollElement.scrollLeft
              + scrollElement.clientWidth) >= (scrollElement.scrollWidth * 0.98)) {
              nextButton.classList.add('visually-disabled');
              nextButton.setAttribute('aria-disabled', 'true');
            } else if (scrollElement.scrollLeft
              + scrollElement.clientWidth < scrollElement.scrollWidth) {
              nextButton.classList.remove('visually-disabled');
              nextButton.setAttribute('aria-disabled', 'false');
            }
          });
        }
      }
    });
  }
};

// Scroller 80% på horisontal scroll til venstre og sætter knappen disabled når man rammer toppen
const horizontalScrollButtonPrevious = (previousButtons: NodeListOf<Element>): void => {
  if (previousButtons) {
    previousButtons.forEach((previousButton) => {
      const topElem = previousButton?.parentElement?.parentElement?.parentElement?.parentElement;
      if (topElem) {
        const scrollElement = topElem.querySelector('.horizontal-scroll__scrollable-content');
        if (scrollElement) {
          previousButton.addEventListener('click', () => {
            // Scroller 80% af den synlige bredde på horizontal scroll til venstre
            const scrollAmount = 0.8 * scrollElement.clientWidth;
            scrollElement.scrollLeft -= scrollAmount;
          });
          // Sætter Aria-label og visually-disabled klassen på knappen hvis scroll har ramt toppen
          scrollElement.addEventListener('scroll', () => {
            if (scrollElement.scrollLeft <= 0) {
              previousButton.classList.add('visually-disabled');
              previousButton.setAttribute('aria-disabled', 'true');
            } else if (scrollElement.scrollLeft > 0) {
              previousButton.classList.remove('visually-disabled');
              previousButton.setAttribute('aria-disabled', 'false');
            }
          });
        }
      }
    });
  }
};


export {
  copyToClipboard,
  isElementWithClassNameInEventPath,
  getBaseFontSize,
  setMetaThemeColor,
  isViewportBelow,
  convertRemToPixels,
  setScrollbarWidthVariable,
  overflowsX,
  overflowsY,
  elementOverflows,
  setHorizontalScroll,
  focusableSelector,
  horizontalScrollButtonNext,
  horizontalScrollButtonPrevious,
};
